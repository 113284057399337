<template>
    <div class="random-sample-widget">
        <div class="col-12 d-flex align-items-center mb-2">
            <div class="col-4 d-flex align-items-center justify-content-between mb-2" style="white-space: nowrap;padding-left:0px !important; padding-right:0px !important">
                Optimiser: 
                <b-form-select class="mr-2" v-model="selectedOptimiser" :options="optimisers" @change="requests"></b-form-select>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-between mb-2" style="padding-left:0px !important; padding-right:0px !important">
                Portfolio: 
                <b-form-select class="mr-2" v-model="selectedPortfolio" :options="portfolioNames" @change="portfolioChange"></b-form-select>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-between mb-2 ml-2" style="padding-left:0px !important; padding-right:0px !important">
                Compare: 
                <b-form-select class="mr-2" v-model="selectedOptimiserCompare" :options="optimisers" @change="requests">
                </b-form-select>
            </div>
         </div>
         <div class="col-6 float-left">
              <div v-if="chartOptions.series.length > 0" class="col-12">
                    <chart @getSVG="getSVG" :key="JSON.stringify(chartOptions.series)"  :chartID="chartIDs[0]" :chartOptions="chartOptions"></chart>
                </div>

                <div class="col-12 d-flex align-items-center justify-content-center">
                <b-table :items="tableOptions.items" :fields="tableOptions.columns" responsive class="text-center col-8" style="margin:0 auto" show-empty>
                    <template slot="empty">
                        No results found
                    </template>
                </b-table>
                </div>
         </div>
        <div class="col-6 float-right">
              <div v-if="chartOptionsCompare.series.length > 0" class="col-12">
                    <chart :key="JSON.stringify(chartOptionsCompare.series)"  :chartID="chartIDs[1]" :chartOptions="chartOptionsCompare"></chart>
                </div>

                <div class="col-12 d-flex align-items-center justify-content-center">
                <b-table :items="tableOptionsCompare.items" :fields="tableOptionsCompare.columns" responsive class="text-center col-8" style="margin:0 auto" show-empty>
                    <template slot="empty">
                        No results found
                    </template>
                </b-table>
                </div>
         </div>

        
    </div>
</template>

<script>
import { addDays, compareAsc, subBusinessDays, isToday, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears, parse } from 'date-fns';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { getColor } from '@/lib/ColorLookup';
import { exportCsv, exportChartWithText } from '@/lib/Exporting';
import {resolveDay} from '@/lib/Helpers'
import chart from '@/widgets/highchart.vue'

export default {
    name: 'random-sample',
    data() {
        let that = this;

        let exportingOptions = {
            filename: "RandomSample",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Random Sampling"}
            },
           
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        };
        let defaultChartOptions =  {
                exporting:exportingOptions,
                chart: {
                    type:"column"
                },
                title: {
                    text: ''
                },
                xAxis: {
                    
                },
                yAxis: {
                    title: {
                        text: 'Count'
                    },
                  
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                series: []
            }
        return {
            chartOptions: {...defaultChartOptions},
            chartOptionsCompare: {...defaultChartOptions},
            tableOptions:{
                columns:[],
                items:[]
            },
            tableOptionsCompare:{
                columns:[],
                items:[]
            },

            selectedPortfolio:"",
            selectedOptimiserCompare: -1,
            portfolioNames:[],
            optimisers: [],
            portfolios:{},
            portfoliosCompare: {},
            selectedOptimiser: -1,

            response: {},
            tableData: {},
            tableDataCompare:{},

            showReturns:false,

            dailyValue:null,
            weeklyValue:null,
            portfoliosWeekly:{},
            portfoliosDaily:{},
            getReturnsOptimiser:null
            
        };
    },
    components:{
        chart
    },
    props:{
        chartIDs:{
            type:Array,
            default:()=>{return ["0","1"]}
        },
        ID:{
            type:Number,
            default: null
        },
        parsedPortfolio:{
            type:String,
            default:null
        },
        parsedOptimiser:{
            type:String,
            default:null
        },
        getReturnsTable:{
            type:Boolean,
            default:false
        },
        date:{
            type:Date,
            default:null
        }
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('randomsample', this.onResponse);
        this.$ws.off('optimiserreturns', this.getSampleReturns);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('randomsample', this.onResponse);
                this.$ws.on('optimiserreturns', this.getSampleReturns);

                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            if(this.parsedPortfolio != null && this.parsedPortfolio != "" && this.parsedPortfolio != "undefined"){
                this.selectedPortfolio = this.parsedPortfolio;
            }
            
            if(this.parsedOptimiser != null && this.parsedOptimiser != "-1" && this.parsedOptimiser != "undefined"){
                this.selectedOptimiser = this.parsedOptimiser;
            }

            if(this.getReturnsTable && this.dailyValue == null){
                this.getReturnsOptimiser = this.selectedOptimiser
                this.$ws.send({
                    sessionID: 'NotImplemented',
                    accountName: account,
                    request: 'OptimiserReturns',
                    args: {
                        Date: this.date != null ? format(this.date, 'yyyy-MM-dd') : format(new Date(Date.now()), 'yyyy-MM-dd'),
                        Optimiser: this.selectedOptimiser,
                        parsedPortfolio:this.selectedPortfolio
                    }
                });
            }else{
                this.$ws.send({
                    sessionID: 'NotImplemented',
                    accountName: account,
                    request: 'RandomSample',
                    args: {
                        optimiser:this.selectedOptimiser,
                        optimiserCompare:this.selectedOptimiserCompare,
                    }
                });
            }
            
        },
        onResponse(event) {
           let response = event.response
           if(Object.keys(response).length <= 0 
           || (this.ID != null && this.parsedOptimiser != null && this.parsedOptimiser != "-1" && this.parsedOptimiser != event.request.args.optimiser)
           ){return;}

           //optimisers
            if(response.hasOwnProperty("optimisers")){
                let optimisers = JSON.parse(response.optimisers)

                //optimiser selection
                let values = Object.values(optimisers)
                let ids = values.map(x=> {return x.id})

                let formattedValues = [];
                for(let value of values){
                    formattedValues.push({value:value.id,text:value.name})
                }
                this.optimisers = formattedValues;

                let current = values.find(x=>x.id == this.selectedOptimiser);
                if(current == null || current == undefined){
                    this.selectedOptimiser = Math.max(...ids);
                }

                this.$emit("getOptimisers", formattedValues, this.selectedOptimiser, "RandomSample")
            }

            //current optimiser
            if(response.hasOwnProperty("portfolios")){
                let portfolios = JSON.parse(response.portfolios)
                this.portfolios = portfolios
                

                //portfolio selection
                this.portfolioNames = Object.keys(portfolios)
                if(this.parsedPortfolio != null && this.parsedPortfolio != "" && this.portfolioNames.includes(this.parsedPortfolio)){
                    this.selectedPortfolio = this.parsedPortfolio;
                }else{
                    this.selectedPortfolio = this.portfolioNames[0]
                }
     
                if(portfolios.hasOwnProperty("portfolio1w")){
                    this.portfoliosWeekly = portfolios.portfolio1w
                }
                if(portfolios.hasOwnProperty("portfolio1d")){
                    this.portfoliosDaily = portfolios.portfolio1d
                }


                this.updateChart(false,event);
            }
            if(response.hasOwnProperty("analysis")){

                let analysis = JSON.parse(response.analysis)

                if(analysis.hasOwnProperty(this.selectedPortfolio)){
                    this.tableData = analysis
                    this.updateTable();
                    
                }
            }
            
            //comparison optimiser
            if(response.hasOwnProperty("portfoliosCompare")){
                let portfolios = JSON.parse(response.portfoliosCompare)
                this.portfoliosCompare = portfolios
                this.updateChart(true)
            }
            if(response.hasOwnProperty("analysisCompare")){

                let analysis = JSON.parse(response.analysisCompare)
                if(analysis.hasOwnProperty(this.selectedPortfolio)){
                    this.tableDataCompare = analysis
                    this.updateTable(true);
                            
                }


                //optimiserid compare
                if(response.hasOwnProperty("optimiserIdCompare")){
                    this.selectedOptimiserCompare = response.optimiserIdCompare;
                }
            }

        },
        getSampleReturns(event){
            let summary = event.response.summary
            //daily return
            let dailyReturns = {};
            let sum = 0;
            let dailyReturnsKeys = ["Monday", "Tuesday", "Wednesday","Thursday", "Friday"]
            let dates = Object.keys(summary)
            for(let i in dailyReturnsKeys){
                let resolvedDay = null
                if(dates.length-1 >= i){
                    resolvedDay = resolveDay(dates[i])
                }
                let summaryReturn = 0
                if(dailyReturnsKeys.includes(resolvedDay)){
                    summaryReturn = (summary[dates[i]].TotalReturn*100);
                }   
                dailyReturns[dailyReturnsKeys[i]] = summaryReturn;
                sum += summaryReturn
            }

            let dailyHeaders = ["Day","Return"]
            let weeklyHeaders = ["WTD","percentile"]

            let formattedDaily = []
            for(let dailyKey of Object.keys(dailyReturns)){
                formattedDaily.push({
                    "Day":dailyKey,
                    "Return":dailyReturns[dailyKey].toFixed(3)+"%"
                })
            }
            let returns = {daily:formattedDaily,weekly:[{"WTD":0,[sum.toFixed(3)+"%"]:0}]}

            this.dailyValue = Object.values(dailyReturns)
            this.weeklyValue = sum

            this.$emit("getRawData", returns, this.ID,"returns");

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: event.request.accountName,
                request: 'RandomSample',
                args: {
                    optimiser:this.selectedOptimiser,
                    optimiserCompare:this.selectedOptimiserCompare,
                    parsedPortfolio:event.request.args.parsedPortfolio
                }
            });
        },
        updateChart(comparison=false,event = null){
            let name = this.selectedPortfolio;
            let portfolios = this.portfolios;
            let chartOptions = this.chartOptions
            if(comparison){
                portfolios = this.portfoliosCompare;
                chartOptions = this.chartOptionsCompare
            }

            if(portfolios.hasOwnProperty(name)){
                let portfolio = portfolios[name];

                let columns = portfolio[0];
                portfolio.splice(0,1);
                let yAxis = [];
                let xAxis = [];
                //sort portfolio
                portfolio = portfolio.sort((a,b)=>Number(a[0]) - Number(b[0]))
               
                for(let row of portfolio){
                    let key = `${(Number(row[0])*100).toFixed(2)}%-${(Number(row[1])*100).toFixed(2)}%`
                    xAxis.push(key)
                    yAxis.push(Number(row[2]))
                }
                chartOptions.xAxis = {categories:xAxis,labels:{
                        style:{
                            fontSize: this.ID == null ? 10 : 28
                        },
                        rotation:-90}}
                //zones
                let p = event != null ? event.request.args.parsedPortfolio : null
                if(event != null && event.request.args.hasOwnProperty("parsedPortfolio") && p!=null
                && this.portfoliosWeekly != undefined && this.portfoliosDaily != undefined
                && this.getReturnsOptimiser == event.request.args.optimiser
                && this.dailyValue != null && this.weeklyValue != null){
                    let value = this.dailyValue
                    value.sort((a,b)=>{return Number(a) - Number(b)})
                    portfolio = this.portfoliosDaily
                    let intervals = value.length
                    if(p == "portfolio1w"){
                        value = this.weeklyValue
                        portfolio = this.portfoliosWeekly
                        intervals = 1
                    }
                    let zones = []
                    let addedZones = false;
                    let mainColor = "#7CB5EC"
                    let todayColor = "#2181db"

                    for(let i =0;i < intervals;i++){
                        let curValue = value
                        if(p != "portfolio1w"){
                            curValue = value[i]
                        }
                        let numbers = portfolio.map(x=>{return x[0]*100})
                        numbers.push(curValue)
                        numbers.sort((a,b)=>{return Number(a) - Number(b)})
                        let index = numbers.indexOf(curValue)

                        if(index >= 0){
                            addedZones = true;
                            //add colour zones to chart
                            zones.push({
                                value:index-1,
                                color:mainColor
                            })
                            zones.push({
                                value:index,
                                color:todayColor
                            })
                        }
                    }
                        zones.push({
                            color:mainColor
                        })

                        if(addedZones){
                            chartOptions.series = [{data:yAxis,zoneAxis:"x",zones:zones}]
                        }else{
                            chartOptions.series = [{data:yAxis}]
                        }
                        
                }else if(!this.getReturnsTable){
                    chartOptions.series = [{data:yAxis}]
                }
            }
        },
        updateTable(comparison=false){
            let tableData = this.tableData
            let tableOptions = this.tableOptions
            if(comparison){
                tableData = this.tableDataCompare
                tableOptions = this.tableOptionsCompare
            }
            if(tableData.hasOwnProperty(this.selectedPortfolio)){
                let data = tableData[this.selectedPortfolio]
                let columns = []
                for(let column of data[0]){
                    columns.push({
                        key:column,
                        label:column,
                        sortable:true
                    })
                }
                let items = []
   
                for(let item of data.slice(1)){
                    let result = (Number(item[1])*100).toFixed(3) + "%"
                    if(["kurtosis","skewness"].includes(item[0].toLowerCase())){
                        result = Number(item[1]).toFixed(3)
                    }
                    items.push({
                        Type:item[0],
                        Result:result
                    })
                }
                tableOptions.columns = columns
                tableOptions.items = items

                if(!comparison && this.ID != null && this.parsedOptimiser != "-1" && this.parsedOptimiser != null && this.getReturnsTable == false){
                    this.$emit("getRawData", items, this.ID,this.selectedPortfolio);
                }
            }
        },
        portfolioChange(){
            //update both tables and charts
            this.updateTable()
            this.updateTable(true)
            this.updateChart()
            this.updateChart(true)
        },
        getSVG(svg){
            if(this.ID != null && this.parsedOptimiser != "-1" && this.parsedOptimiser != null){
                this.$emit("getSVG", svg , this.ID, this.selectedPortfolio);
            }
            
        }
    }
}
</script>